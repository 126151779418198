<template>
  <div>
    <v-col class="ma-0 pa-0" v-if="!isGraphVisible" align="center" justify="middle">
      <v-progress-circular size="100" indeterminate color="primary"></v-progress-circular>
    </v-col>
    <!--Main Graph-->
    <v-col class="ma-0 pa-0" id="mainChartWrapper" v-else>
      <div
        :id="chartId"
        :class="
          !fullScreen && mini_charts_list.length > 0
            ? 'resizableChart chartWithOverlay'
            : 'chartWithOverlay'
        "
        :style="chart_style"
      >
        <GChart
          type="ComboChart"
          :data="rawData"
          :options="chartConfig.mainChartOptions"
          class="performanceChart"
          :resizeDebounce="50"
          :events="chartEvents"
          :ref="fullScreen ? 'fullScreenMainChart' : 'mainChart'"
          :id="fullScreen ? 'fullScreenMainChart' : 'mainChart'"
          :key="mainChartKey"
        />
      </div>
      <div
        class="chartWithOverlay"
        v-for="(chart, idx) in mini_charts_list"
        :key="chart.graph_id"
      >
        <GChart
          type="ComboChart"
          :data="chart.data"
          :options="chart.config"
          class="performanceChart"
          :resizeDebounce="50"
          :key="miniChartKey"
          :style="{
            height: miniChartHeight + 'px',
          }"
          :ref="'miniChart_' + idx"
        />
      </div>
    </v-col>

    <!--Graph Indicators Overlay-->
    <div
      :class="fullScreen ? 'metricToggleOverlayFullSize' : 'metricToggleOverlay'"
      v-if="isGraphVisible"
    >
      <div>
        <v-row class="ma-0 pa-0">
          <v-col cols="auto" class="ma-0 pa-0 graphIndicatorTitle">
            <v-icon color="black" class="mx-1">mdi-function</v-icon>
            <span class="metric_overlay_title">Indicators</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="ma-0 pa-0">
            <v-btn
              icon
              @click="overlay_indicators_visible = false"
              v-if="overlay_indicators_visible"
            >
              <v-icon color="#323232">mdi-window-minimize</v-icon>
            </v-btn>
            <v-btn icon @click="overlay_indicators_visible = true" v-else>
              <v-icon color="#323232">mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="metricToggleWrapper" v-if="overlay_indicators_visible">
        <div
          class="metricToggleRow"
          v-for="indicator in selected_metrics.slice(
            0,
            overlay_indicators_expanded ? selected_metrics.length : 5
          )"
          :key="indicator.metric_id"
        >
          <span class="metric_overlay_metric_title">
            <v-icon
              class="mr-1"
              :color="
                Array.isArray(indicator.graph_color)
                  ? indicator.graph_color[indicator.graph_color.length - 1]
                  : indicator.graph_color
              "
              >mdi-circle</v-icon
            >
            {{ indicator.metric_name }}
          </span>
          <span class="metric_overlay_metric_stat">
            {{
              tooltipMouseOverRow && indicator.visible
                ? dataset[tooltipMouseOverRow][indicator.metric_stat_headers] &&
                  dataset[tooltipMouseOverRow][indicator.metric_stat_headers].toFixed(2)
                : ""
            }}
          </span>
          <v-btn
            icon
            class="toolButton"
            color="black"
            @click="$emit('update_graph', indicator.metric_id)"
          >
            <v-icon v-if="indicator.visible" large>mdi-eye</v-icon>
            <v-icon v-if="!indicator.visible" large>mdi-eye-off-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="selected_metrics.length > 5 && !overlay_indicators_expanded"
        class="metricToggleRow"
        align="center"
      >
        <v-icon @click="overlay_indicators_expanded = true"
          >mdi-chevron-double-down</v-icon
        >
      </div>
      <div
        v-if="selected_metrics.length > 5 && overlay_indicators_expanded"
        class="metricToggleRow"
        align="center"
      >
        <v-icon @click="overlay_indicators_expanded = false"
          >mdi-chevron-double-up</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
import indicator_list from "@/configs/Performance/available_indicators.json";
const lodash = require("lodash");

export default {
  name: "MainChart",
  props: [
    "rawData",
    "dataset",
    "mini_charts_list",
    "fullScreen",
    "chartConfig",
    "chartId",
    "isGraphVisible",
    "selected_metrics",
  ],
  components: {
    GChart,
  },

  data: () => ({
    screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.6 : 0.55),
    miniChartHeight: window.innerHeight > 1000 ? 125 : 100,
    mainChartHeight: 0,
    mainChartKey: 0,
    miniChartKey: 0,
    chartEvents: {},
    overlay_indicators_visible: true,
    overlay_indicators_expanded: false,
    tooltipMouseOverRow: null,
  }),

  mounted() {
    this.mainChartHeight =
      this.screenHeight * 0.97 - this.mini_charts_list.length * this.miniChartHeight;
    (this.chartEvents = {
      onmouseover: (e) => {
        this.tooltipMouseOverRow = e.row;
      },
      select: () => {
        if (this.get_idx_indicator_selected(200) == -1) {
          this.$emit("select_indicator", 200);
        }
        let indicator = this.get_idx_indicator_selected(200);
        let jdx = indicator[0];
        this.$emit("update_selected_metrics", jdx, true);
        const chart = this.fullScreen
          ? this.$refs.fullScreenMainChart.chartObject
          : this.$refs.mainChart.chartObject;
        const markerCol = chart.getSelection()[0]["row"];

        // add marker line to main chart
        this.$emit("update_graph", 200, markerCol);
        this.$emit("reload_graph");
      },
      ready: () => {
        // set up Mutation observer event for main chart to listen to zoom event
        if (this.fullScreen) {
          let fullMainChartContainer = document.getElementById("fullScreenMainChart");
          if (fullMainChartContainer) {
            const fullScreenChart = this.$refs.fullScreenMainChart.chartObject;
            let zoomLast = this.get_chart_coords(fullScreenChart);
            let fullScreenMainChartObserver = new MutationObserver(() => {
              let zoomCurrent = this.get_chart_coords(fullScreenChart);
              if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
                zoomLast = this.get_chart_coords(fullScreenChart);
                this.set_graph_view_window(
                  true,
                  zoomLast["x"]["min"],
                  zoomLast["x"]["max"]
                );
              }
            });
            fullScreenMainChartObserver.observe(fullMainChartContainer, {
              childList: true,
              subtree: true,
            });
          }
        } else {
          let mainChartContainer = document.getElementById("mainChart");
          const chart = this.$refs.mainChart.chartObject;
          let zoomLast = this.get_chart_coords(chart);
          let mainChartObserver = new MutationObserver(() => {
            if (!this.lockHAxis) {
              let zoomCurrent = this.get_chart_coords(chart);
              if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
                zoomLast = this.get_chart_coords(chart);
                this.set_graph_view_window(
                  true,
                  zoomLast["x"]["min"],
                  zoomLast["x"]["max"]
                );
              }
            }
          });
          mainChartObserver.observe(mainChartContainer, {
            childList: true,
            subtree: true,
          });
        }

        let mainChartDiv = document.getElementById("mainChart");
        this.lastMainChartHeight = mainChartDiv.offsetHeight;

        let handleResize = () => {
          let currentHeight = mainChartDiv.offsetHeight;
          if (this.lastMainChartHeight !== currentHeight && currentHeight !== 0) {
            this.lastMainChartHeight = currentHeight;
            this.mainChartHeight = currentHeight;
            this.mainChartKey++;

            this.miniChartHeight =
              (this.screenHeight - this.mainChartHeight) / this.mini_charts_list.length;
            this.miniChartKey++;
          }
        };

        let debounce_func = lodash.debounce(handleResize, 500);

        let resizeObserver = new ResizeObserver(() => {
          debounce_func();
        });
        resizeObserver.observe(mainChartDiv);
      },
    }),
      (this.available_metrics = [...indicator_list["indicators"]]);

    this.available_level_vals = this.data_levels["level_values"];
    if (!this.available_level_vals.includes(this.selected_level_val)) {
      this.selected_level_val = this.available_level_vals[0];
    }
  },

  computed: {
    chart_style: function () {
      if (this.fullScreen) {
        return {
          height: 750 - this.mini_charts_list.length * this.miniChartHeight + "px",
        };
      } else {
        return {
          height:
            this.screenHeight * 0.97 -
            this.mini_charts_list.length * this.miniChartHeight +
            "px",
        };
      }
    },
  },
  methods: {
    get_idx_indicator_selected: function (idx) {
      let jdx = 0;
      for (let indicator of this.selected_metrics) {
        if (indicator["metric_id"] == idx) {
          return [jdx, indicator];
        }
        jdx += 1;
      }
      return -1;
    },
    get_chart_coords: function (chart) {
      var chartLayout = chart.getChartLayoutInterface();
      var chartBounds = chartLayout.getChartAreaBoundingBox();

      return {
        x: {
          min: chartLayout.getHAxisValue(chartBounds.left),
          max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left),
        },
        y: {
          min: chartLayout.getVAxisValue(chartBounds.top),
          max: chartLayout.getVAxisValue(chartBounds.height + chartBounds.top),
        },
      };
    },

    set_graph_view_window: function (isMainChartChanged, min, max) {
      if (isMainChartChanged) {
        if (this.mini_charts_list.length > 0) {
          this.mini_charts_list.forEach((chart) => {
            chart.config["hAxis"]["viewWindow"] = { min: min, max: max };
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.performanceChart {
  height: 100%;
  width: 100%;
}

.resizableChart {
  resize: vertical;
  overflow: hidden;
  border-bottom: 1px solid;
  max-height: 75%;
}

.chartWithOverlay {
  width: 100%;
}

.metricToggleOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 30px;
  left: 70px;
  max-height: 450px;
  overflow-y: auto;
}

.metricToggleOverlayFullSize {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 100px;
  left: 70px;
}

.metric_overlay_title {
  line-height: 1.1em;
  font-family: "Poppins";
  font-weight: 600 !important;
  color: black;
}

metricToggleWrapper {
  display: flex;
  flex-direction: column;
}

.metricToggleRow {
  margin-left: 0;
  margin-right: auto;
}

.metric_overlay_metric_title {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 200px;
  text-align: left;
  overflow: hidden;
  color: black;
}
.metric_overlay_metric_stat {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 70px;
  text-align: right;
  overflow: hidden;
}

.toolButton {
  padding: 0 32px;
  width: 50px;
  color: black;
}

graphIndicatorTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
