<template>
  <v-container class="container">
    <v-card style="height: 250px" flat class="mb-6">
      <GChart
        ref="responseCurve"
        style="height: 250px"
        type="LineChart"
        :data="response_curve_dataset"
        :options="response_curve_config"
        :resizeDebounce="50"
      />
    </v-card>
    <v-card flat>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 py-1">
          <span class="chart_finance_metric_title">ROAS:</span>
          <span class="chart_finance_metric">{{ reported_roas }}</span>
        </v-col>
        <v-col cols="12" class="ma-0 py-1">
          <span class="chart_finance_metric_title">Synergy: </span>
          <span class="chart_finance_metric">{{ reported_synergy }}</span>
        </v-col>
        <v-col cols="12" class="ma-0 py-1">
          <span class="chart_finance_metric_title">Spend: </span>
          <span class="chart_finance_metric">{{ reported_spend }}</span>
        </v-col>
        <v-col cols="12" class="ma-0 py-1">
          <span class="chart_finance_metric_title">Impressions: </span>
          <span class="chart_finance_metric">{{ reported_impr }}</span>
        </v-col>
        <v-col cols="12" class="ma-0 py-1">
          <span class="chart_finance_metric_title">CPM: </span>
          <span class="chart_finance_metric">{{ reported_cpm }}</span>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
import responseChartConfig from "@/configs/Performance/performance_sole_response_curve_config.json";

export default {
  name: "ResponseCurve",
  props: ["dashboard_data"],
  components: {
    GChart,
  },
  data: () => ({
    response_curve_config: responseChartConfig,
    reported_roas: "-",
    reported_spend: "-",
    reported_impr: "-",
    reported_synergy: "-",
    reported_cpm: "-",
    response_curve_dataset: null,
  }),

  watch: {
    dashboard_data: {
      immediate: true,
      handler: function () {
        if (this.dashboard_data) {
          this.reported_roas = this.dashboard_data["roas"].toLocaleString("en-CA", {
            style: "currency",
            currency: "CAD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: "compact",
          });
          this.reported_spend = this.dashboard_data["spend"].toLocaleString("en-CA", {
            style: "currency",
            currency: "CAD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: "compact",
          });
          this.reported_cpm = this.dashboard_data["cpm"].toLocaleString("en-CA", {
            style: "currency",
            currency: "CAD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: "compact",
          });
          this.reported_impr = this.dashboard_data["impressions"].toLocaleString(
            "en-CA",
            {
              notation: "compact",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          );
          this.response_curve_dataset = this.dashboard_data["response_curve"];
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.container {
  padding: unset;
}
.chart_finance_metric_title {
  float: left;
  margin-left: 10px;
  font-family: "Poppins";
  font-weight: 600;
}
.chart_finance_metric {
  float: right;
  margin-right: 10px;
  font-family: "Poppins";
  font-weight: 400;
}
</style>
