var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"container"},[_c('v-card',{style:({ 'max-height': _vm.screen_height + 'px', 'overflow-y': 'auto' })},[_c('v-card-title',[_c('v-icon',{staticClass:"ml-0 mr-1",attrs:{"color":"black","large":""}},[_vm._v("mdi-function")]),_c('span',{staticClass:"metric_toolbar_title"},[_vm._v("Indicators")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"black","large":""},on:{"click":function($event){return _vm.$emit('toggle_indicators_drawer', false);}}},[_vm._v("mdi-close")])],1),_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"width":"100%","height":"100%"}},[_c('v-treeview',{staticStyle:{"width":"100%"},attrs:{"items":_vm.indicator_list_breakdown,"item-key":"metric_id","item-text":"metric_name"},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(item.graph_color)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":Array.isArray(item.graph_color)
                  ? item.graph_color[item.graph_color.length - 1]
                  : item.graph_color}},[_vm._v("mdi-circle")]):_vm._e()]}},{key:"label",fn:function({ item }){return [(!item.description_short)?_c('span',{staticClass:"metric_selection_title"},[_vm._v(_vm._s(item.metric_name))]):_c('span',{staticClass:"metric_selection_metric_title"},[_vm._v(_vm._s(item.metric_name))])]}},{key:"append",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(item.description_short)?_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"color":"#323232"}},[_vm._v("mdi-information-variant")])],1):_vm._e()]}}],null,true)},[_c('span',{staticClass:"tooltip_description"},[_vm._v(_vm._s(item.description_short))])]),(
                item.description_short &&
                !_vm.selected_metric_ids.includes(item.metric_id)
              )?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"black"},on:{"click":function($event){return _vm.$emit('select_indicator', item.metric_id)}}},[_vm._v("mdi-eye ")]):_vm._e(),(
                item.description_short && _vm.selected_metric_ids.includes(item.metric_id)
              )?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"black"},on:{"click":function($event){return _vm.$emit('remove_indicator', item.metric_id)}}},[_vm._v("mdi-eye-off-outline ")]):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }