<template>
    <v-container class="container">
        <v-card :style="{ 'max-height': screen_height + 'px', 'overflow-y': 'auto' }">
            <v-card-title>
            <v-icon class="ml-0 mr-1" color="black" large>mdi-function</v-icon>
            <span class="metric_toolbar_title">Indicators</span>
            <v-spacer></v-spacer>
            <v-icon
              color="black"
              large
              @click="
                $emit('toggle_indicators_drawer', false);
              "
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-row style="width: 100%; height: 100%" class="ma-0 pa-0">
            <v-treeview
              :items="indicator_list_breakdown"
              item-key="metric_id"
              item-text="metric_name"
              style="width: 100%"
            >
              <template v-slot:prepend="{ item }">
                <v-icon
                  class="mr-1"
                  v-if="item.graph_color"
                  :color="
                    Array.isArray(item.graph_color)
                      ? item.graph_color[item.graph_color.length - 1]
                      : item.graph_color
                  "
                  >mdi-circle</v-icon
                >
              </template>
              <template v-slot:label="{ item }">
                <span class="metric_selection_title" v-if="!item.description_short">{{
                  item.metric_name
                }}</span>
                <span class="metric_selection_metric_title" v-else>{{
                  item.metric_name
                }}</span>
              </template>
              <template v-slot:append="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="item.description_short">
                      <v-icon class="ma-0 pa-0" color="#323232"
                        >mdi-information-variant</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="tooltip_description">{{ item.description_short }}</span>
                </v-tooltip>

                <v-icon
                  v-if="
                    item.description_short &&
                    !selected_metric_ids.includes(item.metric_id)
                  "
                  color="black"
                  class="mx-1"
                  @click="$emit('select_indicator', item.metric_id)"
                  >mdi-eye
                </v-icon>
                <v-icon
                  v-if="
                    item.description_short && selected_metric_ids.includes(item.metric_id)
                  "
                  color="black"
                  class="mx-1"
                  @click="$emit('remove_indicator', item.metric_id)"
                  >mdi-eye-off-outline
                </v-icon>
              </template>
            </v-treeview>
          </v-row>
        </v-card>
    </v-container>  
</template>

<script>
import indicator_list from "@/configs/Performance/available_indicators.json";

export default {
    name: 'IndicatorsDrawer',
    props: ["selected_metric_ids", "screen_height"],
    components: {},
    data: () => ({
    }),
    watch: {},
    computed: {
        indicator_list_breakdown: function () {
            let indicator_lst = [
                {
                metric_id: "Data",
                metric_name: "Data",
                children: [],
                },
                {
                metric_id: "Moving Averages",
                metric_name: "Moving Averages",
                children: [
                    {
                    metric_id: "SMA",
                    metric_name: "SMA",
                    children: [],
                    },
                    {
                    metric_id: "EMA",
                    metric_name: "EMA",
                    children: [],
                    },
                ],
                },
                {
                metric_id: "Volatility Metrics",
                metric_name: "Volatility Metrics",
                children: [],
                },
                {
                metric_id: "Analysis",
                metric_name: "Analysis",
                children: [],
                },
                {
                metric_id: "Other",
                metric_name: "Other",
                children: [],
                },
            ];
            for (let indicator of indicator_list["indicators"]) {
                if (
                !Object.keys(indicator).includes("organization") ||
                indicator.organization.length == 0
                ) {
                indicator_lst[4]["children"].push({ ...indicator });
                } else if (indicator.organization.length == 1) {
                indicator_lst[indicator.organization[0]]["children"].push({ ...indicator });
                } else if (indicator.organization.length == 2) {
                indicator_lst[indicator.organization[0]]["children"][indicator.organization[1]][
                    "children"
                ].push({ ...indicator });
                }
            }
            return indicator_lst;
        }
    }
}
</script>

<style scoped>
.container {
    padding: unset;
}

.metric_toolbar_title {
  font-weight: 600 !important;
  font-family: "Poppins";
}

.metric_selection_title {
  line-height: 1.1em;
  font-family: "Poppins";
  font-weight: 600 !important;
}
.metric_selection_metric_title {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100px;
  text-align: left;
  overflow: hidden;
  color: black;
}

.tooltip_description {
  font-family: "Poppins" !important;
  font-weight: 200;
}
</style>